interface AlpineHtmlElement extends HTMLElement {
	_x_model: any;
}

export function rfDadataInputComponent(dadataInput: HTMLInputElement | null = null, modelInput: AlpineHtmlElement | null = null) {
	return {
		state: {
			touched: false,
			dirty: false
		},
		get classes() {
			return {
				'touched': this.state.touched,
				'untouched': !this.state.touched,
				'dirty': this.state.dirty,
				'pristine': !this.state.dirty,
			}
		},
		dadata_input: dadataInput,
		model_input: modelInput,
		suggestionsInstance: null as any,
		init() {
			console.log(this);
			this.initDadataInput(this.dadata_input);
		},

		initDadataInput(dadataInput: HTMLElement | null) {

			if (dadataInput) {
				const jDadataInput: any = $(dadataInput);

				if (jDadataInput.suggestions) {
					jDadataInput.suggestions({
						token: "8b6596088d0c3496ce121d07f47bc2b360cb8331",
						type: "PARTY",
						hint: false,
						minChars: 3,
						noSuggestionsHint: (<any>this).$refs.empty_list_template.innerHTML,

						/* Вызывается, когда пользователь выбирает одну из подсказок */
						onSelect: this.onSelect.bind(this),
						formatResult: this.getResultHandler((<any>this).$refs.option_template.innerHTML)
					});
					this.suggestionsInstance = jDadataInput.suggestions();
				}
			}
		},
		getResultHandler(template: string) {
			return function (value: any, currentValue: any, suggestion: any) {
				return template.replace('Подзаголовок', suggestion.data.address.value).replace('Заголовок', suggestion.value);
				// return template.replace('{{title}}', suggestion.value).replace('{{subtitle}}', suggestion.data.address.value);
			}
		},
		onSelect(suggestion: any) {
			this.state.touched = true;
			this.state.dirty = true;

			this.model_input?._x_model.set(suggestion);
			console.log(suggestion);
		},
		onModeChange(event: any) {
			if (event.detail.mode == 'manual') {
				this.model_input?._x_model.set(null);
				if (this.dadata_input) this.dadata_input.value = '';
				this.suggestionsInstance.setSuggestion(null);
			}
		}
	}
}