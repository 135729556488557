import { isMobile } from './utils';

export const COMMUNITY_API_URL = 'https://concept.rybakovfoundation.ru/diag/api/community/api/v1/communities';
// export const COMMUNITY_API_URL = 'http://localhost:5555/api/v1/communities';

export const DIRECTIONS = ["D7", "D1", "D2", "D3", "D4", "D5", "D6", "D8", "D9"];

export const DIRECTION_LABELS: Record<string, string[]> = {
	"D1": ["Вовлечение", "выпускников"],
	"D2": ["Поддержка", "достижений"],
	"D3": ["Самореализация", "школьников"],
	"D4": ["Профессиональная", "среда"],
	"D5": ["Взаимодействие", "с сообществом"],
	"D6": ["Принятие", "решений"],
	"D7": ["Вовлечение", "семей"],
	"D8": ["Волонтерство"],
	"D9": ["Коммуникация"]
};

export const DIRECTION_COLORS: Record<string, string> = {
	D1: 'rgba(251, 176, 170, 0.5)',
	D2: 'rgba(248, 212, 122, 0.5)',
	D3: 'rgba(171, 200, 255, 0.5)',
	D4: 'rgba(127, 209, 174, 0.5)',
	D5: 'rgba(245, 180, 235, 0.5)',
	D6: 'rgba(203, 180, 240, 0.5)',
	D7: 'rgba(148, 208, 162, 0.5)',
	D8: 'rgba(250, 184, 144, 0.5)',
	D9: 'rgba(138, 179, 255, 0.5)',
};


export const SCHOOL_INDEX_MIDDLE = 20;
export const SCHOOL_INDEX_HIGH = 60;

export const LEVELS_WITH_DIRECTION = [
	{
		label: 'Уровень 1',
		// backgroundColor: 'rgba(90, 99, 160,0)',
		backgroundColor: 'rgba(112, 170, 122, 0.2)',
		// borderColor: '#be9304',
		borderColor: 'rgba(112, 170, 122, 0.1)',
		data: <Record<string, number>>{
			D1: 11.43,
			D2: 14.66,
			D3: 16.07,
			D4: 4.76,
			D5: 8.43,
			D6: 10.29,
			D7: 10.67,
			D8: 10.27,
			D9: 6
		}
	},
	{
		label: 'Уровень 2',
		// backgroundColor: 'rgba(108,212,92, 0)',
		backgroundColor: 'rgba(137, 178, 255, 0.2)',
		// borderColor: 'rgb(108 212 92)',
		borderColor: 'rgb(108 212 92)',
		data: <Record<string, number>>{
			D1: 48.57,
			D2: 50.86,
			D3: 51.79,
			D4: 33.33,
			D5: 34.94,
			D6: 42.65,
			D7: 40,
			D8: 32.19,
			D9: 58
		}
	}
];

export const DIRECTION_LEVELS: Record<string, number[]> = {
	D1: [11.43, 48.57],
	D2: [14.66, 50.86],
	D3: [16.07, 51.79],
	D4: [4.76, 33.33],
	D5: [8.43, 34.94],
	D6: [10.29, 42.65],
	D7: [10.67, 40],
	D8: [10.27, 32.19],
	D9: [6, 58],
}

export const TASK_LABELS: Record<string, string> = {
	D1T1: 'Вовлеченность выпускников в жизнь школы',
	D1T2: 'Участие выпускников в управлении школой',
	D1T3: 'Взаимная поддержка школьного сообщества',
	D2T1: 'Диалог с семьей',
	D2T2: 'Ориентация на индивидуальные особенности',
	D2T3: 'Акцент на достижениях и навыках 21 века',
	D3T1: 'Возможности для выбора образовательной и профессиональной траектории',
	D3T2: 'Помощь сообщества в профориентации',
	D3T3: 'Преодоление экономических барьеров',
	D4T1: 'Получение новых профессиональных навыков, компетенций',
	D4T2: 'Использование потенциала командной работы',
	D4T3: 'Активность в профессиональном сообществе',
	D5T1: 'Использование ресурсов сообщества',
	D5T2: 'Поддержка школы, учащихся и семей местным сообществом',
	D5T3: 'Ресурсная помощь сообществу',
	D6T1: 'Развитие правовой среды школы',
	D6T2: 'Распределение управления',
	D6T3: 'Взаимодействие с местной властью',
	D7T1: 'Поддержка инклюзии и культурного разнообразия',
	D7T2: 'Создание атмосферы открытости и доброжелательности',
	D7T3: 'Развитие отношений в родительской среде',
	D8T1: 'Поддержка волонтерских инициатив',
	D8T2: 'Обучение и мотивация волонтеров',
	D8T3: 'Мониторинг и оценка волонтерской активности',
	D9T1: 'Регулярная обратная связь школы и семей',
	D9T2: 'Доступность администрации школы для всех членов сообщества',
	D9T3: 'Открытость образовательного процесса школы'
};

export const ROLE_COLORS: Record<string, { backgroundColor: string }> = {
	graduate: {
		backgroundColor: '#FFA4CE'
	},
	parent: {
		backgroundColor: '#7FD1AE',
	},
	partner: {
		backgroundColor: '#FBB0AA'
	},
	teacher: {
		backgroundColor: '#ABC8FF'
	},
	student: {
		backgroundColor: '#FFD363'
	}
};

export const ROLE_CODES = [
	'teacher',
	'student',
	'parent',
	'graduate',
	'partner',
];

export const ROLE_LABELS: Record<string, string> = {
	graduate: 'Выпускники',
	parent: 'Родители',
	partner: 'Партнеры',
	teacher: 'Учителя',
	student: 'Ученики'
};

export const SURVEY_ROLES_LABELS: Record<string, string> = {
	graduate: 'Выпускник',
	parent: 'Родитель',
	partner: 'Партнер*',
	teacher: 'Работник школы',
	student: 'Ученик'
}

export const SURVEY_COLORS = [
	{
		// backgroundColor: 'rgba(255, 122, 175, 0.2)',
		backgroundColor: 'rgba(255, 164, 206, 0.2)',
		// borderColor: '#FF7AAF' // pink
		borderColor: 'rgba(255, 164, 206, 1)' // pink
	},
	{
		backgroundColor: 'rgba(137, 178, 255, 0.2)',
		borderColor: 'rgba(137, 178, 255, 1)' // blue
		// backgroundColor: 'rgba(136, 164, 236, 0.2)',
		// borderColor: '#88a4ec' // blue
	},
	{
		backgroundColor: 'rgba(127, 209, 174, 0.2)',
		borderColor: 'rgba(127, 209, 174, 1)', // green
		// backgroundColor: 'rgba(106, 171, 96, 0.2)',
		// borderColor: '#6AAB60', // green
	},
	{
		backgroundColor: 'rrgba(251, 176, 170, 0.2)',
		borderColor: 'rgba(251, 176, 170, 1)', // red
		// backgroundColor: 'rgba(216, 108, 68, 0.2)',
		// borderColor: '#D86C44', // red
	}
];

export const CHART_TEXT_COLOR = '#4D4D46';
export const DATA_LABELS_COLOR = '#3E4035';

export const fontSetting = {
	font: { size: isMobile() ? 13 : 16 },
	color: CHART_TEXT_COLOR
};