declare const Alpine: any;
import * as yup from 'yup';

import { ICommunityViewModel, IDadataOrganizationData, IManualOrganizationData, OrganizationDataType } from '../../../models';
import { ICreateCommunityRequest } from '../../../models/common';
import { COMMUNITY_API_URL } from '../dashboard/constants';
import { rfDadataInputComponent } from './dadata-input.component';
import { rfInputComponent } from './input.component';

document.addEventListener('alpine:init', () => {

	Alpine.data('registration_form', rfRegistrationFormComponent);

	Alpine.data('rf_input', rfInputComponent);

	Alpine.data('dadata_input', rfDadataInputComponent);

	(<any>window)['triggerFocus' as any] = function (element: HTMLElement) {
		var eventType = 'onfocusin' in element ? 'focusin' : 'focus',
			bubbles = 'onfocusin' in element,
			event;

		if ('createEvent' in document) {
			event = document.createEvent('Event');
			event.initEvent(eventType, bubbles, true);
		}
		else if ('Event' in window) {
			event = new Event(eventType, { bubbles: bubbles, cancelable: true });
		}

		element.focus();
		element.dispatchEvent(<Event>event);
	}
});

const manualOrgSchema = yup.object().shape({
	data_type: yup.string().required().equals(['manual']),
	country: yup.string().required(),
	region: yup.string().required(),
	city: yup.string().required(),
	name: yup.string().required()
});

// const dadataOrgSchema= yup.o


const BASE_DT_SURVEY_URL = 'https://concept.rybakovfoundation.ru/diag/';
const BASE_DT_STATS_URL = 'https://concept.rybakovfoundation.ru/diag/stats?c=';

function rfRegistrationFormComponent() {
	return {
		mode: 'dadata' as OrganizationDataType,
		submitted: false,
		is_submitting: false,
		created_community_id: null as string | null,

		survey_link: null as string | null, //BASE_DT_SURVEY_URL + '123',
		stats_link: null as string | null, //BASE_DT_STATS_URL + '123',
		error: null as string | null,
		ref_id: null as string | null,

		model: <any>{
			teachers_count: null,
			students_count: null,
			graduate_estimate: null,

			organization: null,
			country: null,
			region: null,
			city: null,
			org_name: null,

			name: null,
			email: null
		},
		init() {
			console.log(this);
			const params = new URLSearchParams(window.location.search);
			this.ref_id = params.get('ref_id');
			if (params.get('Shp_comid')) {
				const comId = params.get('Shp_comid');
				this.submitted = true;
				this.survey_link = `${BASE_DT_SURVEY_URL}${comId}`;
				this.stats_link = `${BASE_DT_STATS_URL}${comId}`;
			}
		},
		setMode(mode: OrganizationDataType) {
			this.mode = mode;
		},
		async onSubmit(event: Event) {
			event.preventDefault();
			console.log('submit', event, this.model);

			try {
				const communityRequest: ICreateCommunityRequest = this.getCreateCommunityRequest(this.model, this.ref_id);

				this.is_submitting = true;

				const createCommunityResponse = await this.createCommunity(communityRequest);
				if (createCommunityResponse.result == 'ok') {
					this.submitted = true;
					this.survey_link = `${BASE_DT_SURVEY_URL}${createCommunityResponse.id}`;
					this.stats_link = `${BASE_DT_STATS_URL}${createCommunityResponse.id}`;
				}
				else if (createCommunityResponse.payment_url) {
					window.location.href = createCommunityResponse.payment_url;
				}
			} catch (ex) {
				this.error = (<Error>ex).message;
				console.log(ex);
				if ((ex as Error).message == 'Validation') {
					alert('Пожалуйста заполните все поля');
				}
			}
			finally {
				this.is_submitting = false;
			}
		},
		getCreateCommunityRequest(model: ICommunityViewModel, ref_id: string | null): ICreateCommunityRequest {
			const org = !!this.model.organization ?
				this.getOrgInfo(this.model.organization) :
				this.getManualOrgData(model);

			return {
				name: model.name?.trim(),
				email: model.email?.trim(),

				teachers_count: model.teachers_count,
				students_count: model.students_count,
				graduate_estimate: model.graduate_estimate,

				organization: org,
				payment_id: ref_id || undefined
			};
		},
		getManualOrgData(model: ICommunityViewModel) {
			const data = {
				data_type: 'manual',
				country: model.country?.trim() as string,
				region: model.region?.trim() as string,
				city: model.city?.trim() as string,
				name: (<any>model).org_name?.trim() as string
			} as IManualOrganizationData;

			if (!manualOrgSchema.isValidSync(data)) {
				throw new Error('Validation');
			}
			return data;
		},
		async createCommunity(createCommunityRequest: ICreateCommunityRequest): Promise<any> {
			const response = await fetch(COMMUNITY_API_URL, {
				headers: {
					'Content-Type': 'application/json'
				},
				method: 'POST',
				body: JSON.stringify(createCommunityRequest)
			});
			const responseData = await response.json();
			return responseData;
		},
		getOrgInfo(organization: any) {
			const {
				inn,
				name,
				address: {
					unrestricted_value,
					value,
					data: {
						area,
						area_type,
						block,
						block_type,
						city,
						city_type,
						city_district,
						city_district_type,
						country,
						federal_district,
						flat,
						flat_type,
						geo_lat,
						geo_lon,
						geoname_id,
						house,
						house_type,
						region,
						region_type,
						qc_geo,
						settlement,
						settlement_type,
						stead,
						stead_type,
						street,
						street_type,
						timezone
					}
				}
			} = organization.data;

			const org: IDadataOrganizationData = {
				data_type: 'dadata',
				inn,
				name,
				address: {
					unrestricted_value,
					value,
					data: {
						area,
						area_type,
						block,
						block_type,
						city,
						city_type,
						city_district,
						city_district_type,
						country,
						federal_district,
						flat,
						flat_type,
						geo_lat,
						geo_lon,
						geoname_id,
						house,
						house_type,
						region,
						region_type,
						qc_geo,
						settlement,
						settlement_type,
						stead,
						stead_type,
						street,
						street_type,
						timezone
					}
				}
			};
			console.log(org);

			return org;
		}
	}



}