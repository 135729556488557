type InputState = {
	touched: boolean,
	dirty: boolean
}

export function rfInputComponent(input?: HTMLInputElement) {
	return {
		input,
		state: <InputState>{
			touched: false,
			dirty: false
		},
		get classes() {
			return {
				'touched': this.state.touched,
				'untouched': !this.state.touched,
				'dirty': this.state.dirty,
				'pristine': !this.state.dirty,
			}
		},
		inputstate: {
			['@focus']() {
				(<InputState>(<any>this).state).touched = true;
			},
			['@input']() {
				(<InputState>(<any>this).state).dirty = true;
			},
		},
		selectText() {
			if (this.input) {
				this.input.focus();
				this.input.setSelectionRange(0, this.input.value.length);
			}
		}
	}
}
